import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import App from './App';
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure({
  Auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_eGIP1mHGV',
    userPoolWebClientId: 'gn2f6aqdp926rrjnuotv03es9',
  },
  Analytics: {
    disabled: true,
  },
});

const AppWithAuth = withAuthenticator(App, { signUpAttributes: ['email'] });

createRoot(document.getElementById('root')).render(<AppWithAuth />);
