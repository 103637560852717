import teamsList from './teams.json';

export const teamsMap = {
  byInternal: {},
  byExternal: {},
};
for (const { id, name, teamID } of teamsList) {
  teamsMap.byInternal[id] = {
    name,
    teamID,
  };
  teamsMap.byExternal[teamID] = {
    id,
    name,
  };
}

export default teamsMap;
