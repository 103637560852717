import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Dropdown } from 'semantic-ui-react';
import teamsJson from '../constants/teams.json';

const TEAMS = [];
for (const { teamID, name } of teamsJson) {
  TEAMS.push({ key: teamID, value: teamID, text: name });
}

export default ({ user, signOut }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathNameSplit = pathname.split('/');

  function onChangeTeam(_, { value }) {
    navigate('/team/' + value);
  }

  const navItems = [];
  let isAdmin = false;
  if (user && user.attributes && user.attributes['custom:admin']) {
    isAdmin = true;
    let value = null;
    if (pathNameSplit.length > 1 && pathNameSplit[1] === 'team') {
      value = pathNameSplit[2];
    }
    navItems.push({
      content: <Dropdown
        placeholder="Select Team"
        className="team-dropdown"
        search
        selection
        value={value}
        onChange={onChangeTeam}
        options={TEAMS}
      />,
    });
  }
  return (
    <div className="header">
      <div className="header-content">
        <div className={'header-title' + (isAdmin ? ' link' : '')} onClick={isAdmin ? () => navigate('/') : null} >
          UFA GM
        </div>
        <div className="header-nav">
          {navItems.map(({ content }, i) => <div className="header-nav-item" key={i}>
            {content}
          </div>)}
        </div>
        <Button primary onClick={signOut} className="header-logout">Log Out</Button>
      </div>
    </div>
  );
};
