import { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, Confirm, Dropdown, Icon, Loader, Message, Popup } from 'semantic-ui-react';
import request from '../../utils/request';
import GameDisplay from './GameDisplay';
import { toast } from 'react-toastify';

const NOTES_CHARACTER_LIMIT = 50;

const ACTIVE_STATUSES = [
  { key: 1, value: 1, text: 'Active' },
  { key: 2, value: 2, text: 'Questionable' },
  { key: 3, value: 3, text: 'Doubtful' },
  { key: 11, value: 11, text: 'Dressed' },
  { key: 12, value: 12, text: 'Out' },
  { key: 21, value: 21, text: 'Not Rostered' },
  { key: 0, value: 0, text: 'Unknown' },
];

const SORTS = [
  {
    func: (a, b) => String(a.name).localeCompare(b.name),
    name: 'First Name',
    next: 1,
  },
  {
    func: (a, b) => Number(a.jerseyNumber) - Number(b.jerseyNumber),
    name: 'Jersey Number',
    next: 2,
  },
  {
    func: (a, b) => String(a.lastName).localeCompare(b.lastName),
    name: 'Last Name',
    next: 0,
  },
];

const GAME_FIELDS = [
  { key: 'ticketURL' },
  { key: 'locationID' },
];

const ROSTER_FIELDS = [
  { key: 'active' },
  { key: 'notes' },
];

function validateChangesToGame(newChanges, newGame) {
  if (!newChanges || !Object.keys(newChanges).length) { return {}; }
  try {
    let corrections = false;
    for (const { key } of GAME_FIELDS) {
      if ((newChanges[key] || newChanges[key] === '') && newChanges[key] === newGame[key]) {
        delete newChanges[key];
        corrections = true;
      }
    }
    if (newChanges.roster) {
      const newGameRosterMap = {};
      for (const roster of newGame.roster) {
        newGameRosterMap[roster.id] = {};
        for (const { key } of ROSTER_FIELDS) {
          newGameRosterMap[roster.id][key] = roster[key];
        }
      }
      for (const id in newChanges.roster) {
        if (!newGameRosterMap[id]) {
          delete newChanges.roster[id];
          corrections = true;
        } else {
          for (const { key } of ROSTER_FIELDS) {
            if ((newChanges.roster[id][key] || newChanges.roster[id][key] === '') && (newChanges.roster[id][key] === newGameRosterMap[id][key] || (key === 'active' && newGame.rosterSet1 && (newChanges.roster[id].active > 20 || newGameRosterMap[id][key] > 20)))) {
              delete newChanges.roster[id][key];
              corrections = true;
            }
          }
          if (!Object.keys(newChanges.roster[id]).length) {
            delete newChanges.roster[id];
          }
        }
      }
    }
    if (corrections) {
      if (newChanges.roster && !Object.keys(newChanges.roster).length) {
        delete newChanges.roster;
      }
      localStorage.setItem(newGame.localKey, JSON.stringify(newChanges));
    }
    return newChanges;
  } catch (err) {
    console.error(err);
  }
  return {};
}

export default ({ teamID, game, updateGames, locations, admin, startOpen }) => {
  const [open, setOpen] = useState(startOpen);
  const [confirmDiscard, setConfirmDiscard] = useState(false);
  const [sort, setSort] = useState(SORTS[0]);
  const [dragNDrop] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const [changes, setChanges] = useState({});

  useEffect(() => {
    setChanges(validateChangesToGame(JSON.parse(localStorage.getItem(game.localKey)), game));
  }, [game]);

  const rosterGroups = [
    { title: 'Playing', roster: [] },
    { title: 'Not Playing', roster: [] },
    { title: 'Not Rostered', roster: [] },
  ];
  for (const roster of game.roster) {
    let active = roster.active;
    if (changes.roster && changes.roster[roster.id] && changes.roster[roster.id].active) {
      active = changes.roster[roster.id].active;
    }
    if (active > 0 && active < 10) {
      rosterGroups[0].roster.push(roster);
    } else if (active > 10 && active < 20) {
      rosterGroups[1].roster.push(roster);
    } else {
      rosterGroups[2].roster.push(roster);
    }
  }

  for (const rosterGroup of rosterGroups) {
    rosterGroup.roster.sort(sort.func);
  }

  let hasChanges = false;
  if ((changes.ticketURL || changes.ticketURL === '') || changes.locationID || (changes.roster)) {
    hasChanges = true;
  }

  function onClose() {
    setError(null);
    setOpen(false);
  }

  async function discard() {
    setConfirmDiscard(false);
    localStorage.removeItem(game.localKey);
    setChanges({});
    onClose();
  }

  async function save() {
    if (changes && changes.roster) {
      for (const id in changes.roster) {
        if (changes.roster[id].notes && changes.roster[id].notes.length > NOTES_CHARACTER_LIMIT) {
          setError(`Roster notes can not be longer than ${NOTES_CHARACTER_LIMIT} characters.`);
          return;
        }
      }
    }
    setSaving(true);
    setError(null);
    const response = await request('post', process.env.REACT_APP_EXPRESS_URL + 'teams/' + teamID + '/games/' + game.gameID, changes);

    if (response.error) {
      setError('Error saving data: ' + response.errorMessage);
    } else if (Array.isArray(response.errors) && response.errors.length) {
      setError('Error saving data: ' + response.errors.join(' '));
    } else if (!Array.isArray(response.games)) {
      setError('Error saving data: Server didn\'t send the expected result');
    } else {
      updateGames(response.games);
      setOpen(false);
      toast.success('Changes saved successfully!');
    }
    setSaving(false);
  }

  function setGameData(key, value) {
    const newChanges = Object.assign({}, changes);
    if (game[key] === value) {
      delete newChanges[key];
    } else {
      newChanges[key] = value;
    }
    localStorage.setItem(game.localKey, JSON.stringify(newChanges));
    setChanges(newChanges);
  }

  function setRosterData(roster, key, value) {
    const newChanges = Object.assign({}, changes);
    if (roster[key] === value) {
      newChanges.roster[roster.id] = Object.assign({}, newChanges.roster[roster.id]);
      delete newChanges.roster[roster.id][key];
      if (!Object.keys(newChanges.roster[roster.id]).length) {
        newChanges.roster = Object.assign({}, newChanges.roster);
        delete newChanges.roster[roster.id];
        if (!Object.keys(newChanges.roster).length) {
          delete newChanges.roster;
        }
      }
    } else {
      newChanges.roster = Object.assign({}, newChanges.roster);
      newChanges.roster[roster.id] = Object.assign({}, newChanges.roster[roster.id]);
      newChanges.roster[roster.id][key] = value;
    }
    localStorage.setItem(game.localKey, JSON.stringify(newChanges));
    setChanges(newChanges);
  }

  function cycleFilter(e) {
    setSort(SORTS[sort.next]);
  }

  return (
    <Modal
      closeIcon
      onClose={onClose}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<GameDisplay game={game} onClick={() => setOpen(true)} hasChanges={hasChanges} />}
    >
      <Modal.Header>Update Game {game.isHome ? 'vs' : '@'} {game.teamNameOpp} on {game.startDateTimeNoTZ.toFormat('ccc, MMM d')} </Modal.Header>
      <Modal.Content>
        {game.isHome ? <Form>
          <Form.Field>
            <label>Ticket URL</label>
            <Input
              placeholder="Ticket URL"
              value={(changes.ticketURL || changes.ticketURL === '' ? changes.ticketURL : game.ticketURL || '')}
              onChange={(_, { value }) => setGameData('ticketURL', value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Location</label>
            <Dropdown
              placeholder="Location"
              className="roster-status"
              selection
              basic
              value={changes.locationID || game.locationID || ''}
              onChange={(_, { value }) => setGameData('locationID', value)}
              options={locations}
            />
          </Form.Field>
        </Form> : null}
        <div className="roster-title">
          <div className="roster-title-text">Set Roster</div>
          <Popup
            className="sort-toggle"
            content={'Sorted by ' + sort.name}
            trigger={<Icon name="filter" onClick={cycleFilter} />}
            on={['hover']}
            position="right center"
          />
        </div>
        {dragNDrop ? null : <div className="roster-groups">
          {rosterGroups.map((rosterGroup, i) => {
            return (
              <div key={i} className="roster-group">
                <div className="roster-group-title">
                  {rosterGroup.title} ({rosterGroup.roster.length})
                </div>
                <div className="roster-list">
                  {rosterGroup.roster.map((roster) => {
                    let notes = roster.notes || '';
                    if (changes.roster && changes.roster[roster.id] && (changes.roster[roster.id].notes || changes.roster[roster.id].notes === '')) {
                      notes = changes.roster[roster.id].notes;
                    }
                    let active = roster.active;
                    if (changes.roster && changes.roster[roster.id] && changes.roster[roster.id].active) {
                      active = changes.roster[roster.id].active;
                    }
                    return (
                      <div key={roster.id} className="roster-item">
                        <div className="roster-jersey-number">{roster.jerseyNumber}
                        </div>
                        <div className="roster-player">{roster.name}
                        </div>
                        <Dropdown
                          placeholder="Status"
                          className="roster-status"
                          selection
                          basic
                          disabled={!admin && game.rosterSet1 && active === 21}
                          value={active}
                          onChange={(_, { value }) => setRosterData(roster, 'active', value)}
                          options={ACTIVE_STATUSES.filter(({ key }) => {
                            if (key === active) { return true; }
                            if (!key) { return false; }
                            if (!admin && game.rosterSet1 && key === 21) { return false; }
                            return true;
                          })}
                        />
                        <Input
                          placeholder="Notes"
                          className="roster-notes"
                          error={notes.length > NOTES_CHARACTER_LIMIT}
                          value={notes}
                          onChange={(_, { value }) => setRosterData(roster, 'notes', value)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>}
        {error ? <Message
          error
          content={error}
        /> : null}
        <Loader active={saving}>Saving</Loader>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          content="Discard Changes"
          disabled={!hasChanges || saving}
          onClick={() => setConfirmDiscard(true)}
        />
        <Button
          content="Save Changes"
          onClick={save}
          disabled={!hasChanges || saving}
          positive
        />
      </Modal.Actions>
      <Confirm
        open={confirmDiscard}
        onCancel={() => setConfirmDiscard(false)}
        onConfirm={discard}
      />
    </Modal>
  );
};
