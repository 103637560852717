import teams from '../constants/teams.json';
import UpcomingGameReport from './UpcomingGameReport';

export default () => {
  return (
    <div className="home-page">
      <div className="teams">
        <h2>Teams</h2>
        <div className="team-list">
          {teams.map(({ teamID, name }) => <a className="team" key={teamID} href={'team/' + teamID}>
            <img src={`${process.env.REACT_APP_AUDL_URL}/sites/default/files/styles/thumbnail/public/teams/${teamID}/${teamID}_thumbnail.png`} alt={name} />
            {name}
          </a>)}
        </div>
      </div>
      <UpcomingGameReport />
    </div>
  );
};
