import { useEffect, useState, useCallback } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import teamsMap from '../constants/teamsMap';
import request from '../utils/request';

export default () => {
  const [games, setGames] = useState();
  const [loadingGames, setLoadingGames] = useState(true);
  const [errorMessageGames, setErrorMessageGames] = useState();

  const loadGamesCallback = useCallback(loadGames, []);
  useEffect(() => {
    loadGamesCallback();
    const interval = setInterval(loadGamesCallback, 1000 * 60 * 5);
    return () => {
      clearInterval(interval);
    };
  }, [loadGamesCallback]);

  async function loadGames() {
    setLoadingGames(true);
    const response = await request('get', process.env.REACT_APP_EXPRESS_URL + 'upcomingGames');
    if (response.error) {
      setErrorMessageGames('Error loading games: ' + response.errorMessage);
    } else {
      setErrorMessageGames(null);
      setGames(response.map((game) => formatGame(game)).sort((a, b) => a.startDateTimeNoTZ - b.startDateTimeNoTZ));
    }
    setLoadingGames(false);
  }

  function formatGame(game) {
    game.startDateTimeNoTZ = DateTime.fromISO(game.startTimestamp.substring(0, 19), { zone: 'utc' });
    if (teamsMap.byInternal[game.teamIDAway]) {
      game.extTeamIDAway = teamsMap.byInternal[game.teamIDAway].teamID;
      game.imageSrcAway = `${process.env.REACT_APP_AUDL_URL}/sites/default/files/styles/thumbnail/public/teams/${game.extTeamIDAway}/${game.extTeamIDAway}_thumbnail.png`;
      game.teamNameAway = teamsMap.byInternal[game.teamIDAway].name;
    } else {
      game.imageSrcAway = `${process.env.REACT_APP_AUDL_URL}/sites/default/files/AUDL%400%2C1x_1.png`;
      game.teamNameAway = 'Unknown Away Team';
    }
    if (teamsMap.byInternal[game.teamIDHome]) {
      game.extTeamIDHome = teamsMap.byInternal[game.teamIDHome].teamID;
      game.imageSrcHome = `${process.env.REACT_APP_AUDL_URL}/sites/default/files/styles/thumbnail/public/teams/${game.extTeamIDHome}/${game.extTeamIDHome}_thumbnail.png`;
      game.teamNameHome = teamsMap.byInternal[game.teamIDHome].name;
    } else {
      game.imageSrcHome = `${process.env.REACT_APP_AUDL_URL}/sites/default/files/AUDL%400%2C1x_1.png`;
      game.teamNameHome = 'Unknown Home Team';
    }
    return game;
  }

  if (loadingGames) {
    return <Loader active />;
  } else if (errorMessageGames) {
    return (
      <div className="loading-errors">
        {errorMessageGames ? <Message
          error
          content={errorMessageGames}
        /> : null}
      </div>
    );
  } else if (!Array.isArray(games)) {
    return (
      <div className="error-box">
        Server did not return correct data. Contact Ben Nelson for details.
      </div>
    );
  } else {
    return (
      <div className="upcoming-games">
        <h2>Upcoming Games</h2>
        {games.map((game, i) => <div key={i} className="upcoming-game" >
          <div className="upcoming-game-column">
            <div className="upcoming-game-count">
              Playing: <span className={game.rosterSet1 && game.playingAway > 20 ? 'game-roster-error' : ''}>{game.playingAway}</span>
            </div>
            <div className="upcoming-game-count">
              Rostered: <span className={game.rosteredAway > 30 ? 'game-roster-error' : ''}>{game.rosteredAway}</span>
            </div>
          </div>
          <div className="upcoming-game-info">
            <a href={game.extTeamIDAway ? 'team/' + game.extTeamIDAway : null}>
              <img src={game.imageSrcAway} alt={game.teamNameAway} />
            </a>
            <div className="upcoming-game-date">
              {game.startDateTimeNoTZ.toFormat('ccc, MMM d')}
            </div>
            <a href={game.extTeamIDHome ? 'team/' + game.extTeamIDHome : null}>
              <img src={game.imageSrcHome} alt={game.teamNameHome} />
            </a>
          </div>
          <div className="upcoming-game-column">
            <div className="upcoming-game-count">
              Playing: <span className={game.rosterSet1 && game.playingHome > 20 ? 'game-roster-error' : ''}>{game.playingHome}</span>
            </div>
            <div className="upcoming-game-count">
              Rostered: <span className={game.rosteredHome > 30 ? 'game-roster-error' : ''}>{game.rosteredHome}</span>
            </div>
          </div>
        </div>)}
      </div>
    );
  }
};
