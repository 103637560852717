import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Loader, Message } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import teamsMap from '../../constants/teamsMap';
import request from '../../utils/request';
import GameModal from './GameModal';

const TODAY_NO_TZ = DateTime.fromISO(DateTime.now().toISO().substring(0, 19), { zone: 'utc' });
const START_OF_THIS_WEEK = TODAY_NO_TZ.minus({ days: 3 }).startOf('week').plus({ days: 3 });
const END_OF_THIS_WEEK = START_OF_THIS_WEEK.plus({ days: 7 });
const END_OF_NEXT_WEEK = END_OF_THIS_WEEK.plus({ days: 7 });

export default ({ admin, extTeamID }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [games, setGames] = useState();
  const [loadingGames, setLoadingGames] = useState(true);
  const [errorMessageGames, setErrorMessageGames] = useState();
  const [locations, setLocations] = useState();
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [errorMessageLocations, setErrorMessageLocations] = useState();
  if (!extTeamID) {
    extTeamID = params.extTeamID;
  }
  const team = teamsMap.byExternal[extTeamID];
  if (!team) {
    navigate('/');
  }

  const loadGamesCallback = useCallback(loadGames, []);
  const loadLocationsCallback = useCallback(loadLocations, []);
  useEffect(() => {
    loadGamesCallback(team.id);
    loadLocationsCallback(team.id);
  }, [team.id, loadGamesCallback, loadLocationsCallback]);

  async function loadGames(teamID) {
    setLoadingGames(true);
    const response = await request('get', process.env.REACT_APP_EXPRESS_URL + 'teams/' + teamID + '/games');
    if (response.error) {
      setErrorMessageGames('Error loading games: ' + response.errorMessage);
    } else {
      setErrorMessageGames(null);
      setGames(response.map((game) => formatGame(game, teamID)));
    }
    setLoadingGames(false);
  }

  function formatGame(game, teamID) {
    game.startDateTimeNoTZ = DateTime.fromISO(game.startTimestamp.substring(0, 19), { zone: 'utc' });
    if (teamID === game.teamIDAway) {
      game.teamIDOpp = game.teamIDHome;
      game.isHome = false;
    } else {
      game.teamIDOpp = game.teamIDAway;
      game.isHome = true;
    }
    game.teamNameOpp = 'Unknown';
    if (teamsMap.byInternal[game.teamIDOpp]) {
      game.extTeamIDOpp = teamsMap.byInternal[game.teamIDOpp].teamID;
      game.teamNameOpp = teamsMap.byInternal[game.teamIDOpp].name;
    }
    game.localKey = `tsg|${teamID}|${game.gameID}`;
    game.activePlayers = 0;
    game.rosteredPlayers = 0;
    for (const roster of game.roster) {
      roster.name = roster.firstName + ' ' + roster.lastName;
      roster.notes = roster.notes || '';
      if (roster.active > 0 && roster.active < 20) {
        game.rosteredPlayers++;
        if (roster.active < 10) {
          game.activePlayers++;
        }
      }
    }
    return game;
  }

  async function loadLocations(teamID) {
    setLoadingLocations(true);
    const response = await request('get', process.env.REACT_APP_EXPRESS_URL + 'teams/' + teamID + '/locations');
    if (response.error) {
      setErrorMessageLocations('Error loading locations: ' + response.errorMessage);
    } else {
      setErrorMessageLocations(null);
      setLocations(response.map(formatLocation).sort((a, b) => String(a.name).localeCompare(b.name)));
    }
    setLoadingLocations(false);
  }

  function formatLocation({ id, name }) {
    return {
      key: id,
      value: id,
      text: name,
    };
  }

  function updateGames(updatedGames) {
    const newGames = Object.assign([], games);
    for (const game of updatedGames) {
      const newGame = formatGame(game, team.id);
      const newGameIndex = newGames.findIndex(({ gameID }) => gameID === game.gameID);
      if (newGameIndex === -1) {
        newGames.push(newGame);
      } else {
        newGames[newGameIndex] = newGame;
      }
    }
    setGames(newGames);
  }

  if (loadingGames || loadingLocations) {
    return <Loader active />;
  } else if (errorMessageGames || errorMessageLocations) {
    return (
      <div className="loading-errors">
        {errorMessageGames ? <Message
          error
          content={errorMessageGames}
        /> : null}
        {!errorMessageGames && errorMessageLocations ? <Message
          error
          content={errorMessageLocations}
        /> : null}
      </div>
    );
  } else if (!Array.isArray(games) || !Array.isArray(locations)) {
    return (
      <div className="error-box">
        Server did not return correct data. Contact Ben Nelson for details.
      </div>
    );
  } else if (!games.length) {
    return (
      <div className="error-box">
        No upcoming games to manage.
      </div>
    );
  } else {
    const groups = [
      { title: 'This week\'s games', games: [] },
      { title: 'Next week\'s games', games: [] },
      { title: 'Future games', games: [] },
      { title: 'Past games', games: [] },
    ];
    for (const game of games) {
      let groupIndex = 2;
      if (game.startDateTimeNoTZ < START_OF_THIS_WEEK) {
        groupIndex = 3;
      } else if (game.startDateTimeNoTZ < END_OF_THIS_WEEK) {
        groupIndex = 0;
      } else if (game.startDateTimeNoTZ < END_OF_NEXT_WEEK) {
        groupIndex = 1;
      }
      groups[groupIndex].games.push(game);
    }
    const finalGroups = [];
    for (const group of groups) {
      if (group.games.length) {
        group.games.sort((a, b) => a.startDateTimeNoTZ - b.startDateTimeNoTZ);
        finalGroups.push(group);
      }
    }
    return (
      <div className="game-groups">
        {finalGroups.map(({ title, games: gamesInGroup }, i) => <div key={i} className="game-group" >
          <h2>{title}</h2>
          <div className="games">
            {gamesInGroup.map((game) => <GameModal
              admin={admin}
              key={game.gameID}
              game={game}
              updateGames={updateGames}
              teamID={team.id}
              locations={locations}
            />)}
          </div>
        </div>)}
      </div>
    );
  }
};
