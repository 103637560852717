import { useState } from 'react';
import { Modal, Button, Form, TextArea, Loader, Message } from 'semantic-ui-react';
import request from '../utils/request';

export default () => {
  const [open, setOpen] = useState(false);
  const [changes, setChanges] = useState('');
  const [error, setError] = useState();
  const [sendingChanges, setSendingChanges] = useState(false);

  async function sendSlackMessage() {
    setSendingChanges(true);
    setError(false);
    const response = await request('post', process.env.REACT_APP_EXPRESS_URL + 'slack-message', { text: changes });
    if (response && response.error) {
      setError(response.errorMessage);
    } else {
      setChanges('');
      setOpen(false);
    }
    setSendingChanges(false);
  }

  return (
    <div className="footer">
      <Modal
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button primary content="Need changes made?" />}
      >
        <Modal.Header>Ask for changes</Modal.Header>
        <Modal.Content>
          <Loader active={sendingChanges} />
          <Form>
            <TextArea
              placeholder="Requests for player or home stadium changes."
              value={changes}
              onChange={(_, { value }) => setChanges(value)}
            />
          </Form>
          {error ? <Message
            error
            content={error}
          /> : null}
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            content="Send request"
            disabled={changes === ''}
            onClick={sendSlackMessage}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};
