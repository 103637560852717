import { useNavigate } from 'react-router-dom';
import { Message } from 'semantic-ui-react';

export default ({ teamID }) => {
  const navigate = useNavigate();
  return (
    <div className="error-page">
      <Message
        className="link"
        error
        content="You are not authorized to view this team. Click this box to go to your team's page."
        onClick={() => navigate('/team/' + teamID)}
      />
    </div>
  );
};
