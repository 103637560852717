export default ({ game, onClick, hasChanges }) => {
  const rosterTitle = 'Playing';
  const rosterCount = game.activePlayers;
  const tooManyPlayers = rosterCount > 20;
  let oppImageSrc = `${process.env.REACT_APP_AUDL_URL}/sites/default/files/AUDL%400%2C1x_1.png`;
  if (game.extTeamIDOpp) {
    oppImageSrc = `${process.env.REACT_APP_AUDL_URL}/sites/default/files/styles/thumbnail/public/teams/${game.extTeamIDOpp}/${game.extTeamIDOpp}_thumbnail.png`;
  }
  return (
    <div className="game" onClick={onClick}>
      <div className="game-date">
        {game.startDateTimeNoTZ.toFormat('ccc, MMM d')}
      </div>
      <div className="game-opponent">
        {game.isHome ? 'VS' : '@'}
        <img src={oppImageSrc} alt={game.teamNameOpp} />
      </div>
      <div className="game-roster">
        {rosterTitle}: <span className={tooManyPlayers ? 'game-roster-error' : null}>{rosterCount}</span>
      </div>
      <div className="game-changes">
        {hasChanges ? '** Unsaved Changed **' : ''}
      </div>
    </div>
  );
};
