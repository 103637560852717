import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import './index.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';
import teamsMap from './constants/teamsMap';
import AdminHomePage from './routes/AdminHomePage';
import Games from './routes/Games';
import WrongTeam from './routes/WrongTeam';

export default ({ user, signOut }) => {
  let content = <div>
    Your user doesn't have permissions. Contact Matt Rezin, Tim Debyl, or Ben Nelson on Slack to get permissions.
    <Button primary style={{ marginTop: '10px' }} onClick={() => Auth.currentAuthenticatedUser({ bypassCache: true })} >Check Permissions</Button>
  </div>;
  if (user && user.attributes) {
    if (user.attributes['custom:admin']) {
      content = <Routes>
        <Route path="/team/:extTeamID" element={<Games admin />} />
        <Route path="/" element={<AdminHomePage admin />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>;
    } else if (user.attributes['custom:teamId'] && teamsMap.byInternal[user.attributes['custom:teamId']]) {
      const { teamID } = teamsMap.byInternal[user.attributes['custom:teamId']];
      content = <Routes>
        <Route path={'/team/' + teamID} element={<Games extTeamID={teamID} />} />
        <Route path={'/team/:extTeamID'} element={<WrongTeam teamID={teamID} />} />
        <Route path="*" element={<Navigate to={'/team/' + teamID} />} />
      </Routes>;
    }
  }

  return <BrowserRouter>
    <ToastContainer pauseOnFocusLoss={false} autoClose={2000} />
    <Header user={user} signOut={signOut} />
    <div className="container">
      {content}
    </div>
    <Footer user={user} />
  </BrowserRouter>;
};
